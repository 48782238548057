import React from "react"
import logo from '../../../logo.svg';
import styles from './Main.module.css';
import { Link } from "react-router-dom";

class Main extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            message: null
        }

        this.fetchRandom = this.fetchRandom.bind(this)
    }

    fetchRandom() {
        fetch("/api", {mode: "cors"})
            .then(res => res.json())
            .then(message => this.setState({ message: message.message }))

        return false
    }

    componentDidMount() {
        this.fetchRandom()
    }

    render() {
        return (
            <div className={styles.App}>
                <header className={styles.App_header}>
                <img src={logo} className={styles.App_logo} alt="logo" />
                <p onClick={this.fetchRandom}>Your random number is: {Math.round(this.state.message * 10000)}</p>
                <p>hi zobbify</p>
                <Link to="test">Yippee!</Link>
                <Link to="game">Gaming!</Link>
                </header>
            </div>
        )
    };
  }

export default Main