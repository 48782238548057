import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'

const useSocket = (serverURL = 'ws://localhost:4000') => {
    const [socket, setSocket] = useState(null)

    useEffect(() => {
        const newSocket = process.env.NODE_ENV === "development" 
        ? io(serverURL) 
        : io()

        setSocket(newSocket)

        return () => {
            newSocket.disconnect()
        }
    }, []) // Empty dependency array ensures it only runs once

    return socket
}

export default useSocket