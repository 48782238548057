import React from "react"
import "./Style.css"

class Game extends React.Component {
    // constructor(props) {
    //     super(props)
    // }

    render() {
        return <div style={{fontSize: "100px"}}>
            work in progress!
        </div>
    }
}

export default Game