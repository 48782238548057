import React from "react"
import "./Style.css"
// import { Link } from "react-router-dom";

const keysPressed = {}

function MovementTest() {
    return (
        <div>
            <Player />
        </div>
    )
}

class Player extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            left: 0,
            top: 0
        }

        this.move = this.move.bind(this)
    }

    componentDidMount() {
        document.addEventListener("keydown", (event) => {
            keysPressed[event.key] = true

            Object.keys(keysPressed).forEach(key => {
                if (key === "ArrowUp") {
                    this.move({up: 1})
                }
                if (key === "ArrowDown") {
                    this.move({down: 1})
                } 
                if (key === "ArrowRight") {
                    this.move({right: 1})
                } 
                if (key === "ArrowLeft") {
                    this.move({left: 1})
                }
            });

        })

        document.addEventListener("keyup", (event) => {
            delete keysPressed[event.key]
        })
    }

    move(vectors) {
        const normalizedVectors = {
            left: vectors.left || 0,
            right: vectors.right || 0,
            bottom: vectors.down || 0,
            top: vectors.up || 0
        }

        const topTotal = (-1 * normalizedVectors.top) + normalizedVectors.bottom
        const leftTotal  = (-1 * normalizedVectors.left) + normalizedVectors.right

        this.setState((state, props) => ({
            top: state.top + topTotal,
            left: state.left + leftTotal
        }))
    }

    render() {
        const style = {
            left: this.state.left,
            top: this.state.top
        }

        return <div id="player" className="player" style={style}>
            ratio bozo
        </div>
    }
}

export default MovementTest