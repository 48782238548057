import { BrowserRouter, Route, Routes } from "react-router-dom";

const subdomain = window.location.host.split(".")[0];

const importAll = (r) => {
    return r.keys().map((key) => {
        const componentName = key.match(/\/([^/]+)\/component\.js$/)?.[1];

        return {
            name: componentName,
            Component: r(key).default,
            customUrl: (r(key).customUrl || null),
        }
    })
}

const pageComponents = importAll(require.context('./pages', true, /component\.js$/));

function App() {
    if (subdomain === "ratio") {
        return ( 
            <BrowserRouter>
                <Routes>
                    {/* <Route path="*" element={<Game />}/> */}
                </Routes>
            </BrowserRouter>
        )
    }
    
    return (
        <BrowserRouter>
            <Routes>
                {pageComponents.map(({ name, Component, customUrl }, index) => {
                    const paths = customUrl || [name.toLowerCase()]

                    return paths.map(path => (
                        <Route key={index} path={
                            name === "Main" ? "" : 
                            name === "NoPage" ? "*" : 
                            path
                        } element={<Component />} />
                    ))
                })}
            </Routes>
        </BrowserRouter>
    )
}

export default App;
