import React from "react"
import "./Style.css"

class LettersOnScreen extends React.Component {
    constructor(props) {
		super(props)
		this.state = {
			currentKey: 0,
            letters: []
		}

		this.handleKeypress = this.handleKeypress.bind(this)
		this.displayLetter = this.displayLetter.bind(this)
	}

    componentDidMount() {
		document.addEventListener("keydown", this.handleKeypress, false)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeypress, false)
	}

    handleKeypress(event) {
        let key = event.key

        if (key.length > 1) {
            return
        }

        this.displayLetter(key)
    }

	displayLetter(letterText) {
        this.setState(prevState => {
			return {
				currentKey: prevState.currentKey + 1,
				letters: [<Letter letter={letterText} key={this.state.currentKey} zIndex={this.state.currentKey} />, ...prevState.letters, ]
			}
		})
    }

    render() {
        return <div style={{backgroundColor: "#000000", height: "100vh", margin: "0", zIndex: -10, position: "relative", overflow: "hidden"}}>
            {this.state.letters}
        </div>
    }
}

class Letter extends React.Component {
	constructor(props) {
		super(props)
		this.state = { }
	}

	randomHex() {
		let red = Math.ceil(Math.random() * 255) - 1 
		let green = Math.ceil(Math.random() * 255) - 1 
		let blue = Math.ceil(Math.random() * 255) - 1 

		let hexArray = [red.toString(16), green.toString(16), blue.toString(16)]

		return "#" + hexArray.map(x => x.padStart(2, 0)).join("")
	}

	render() {
		return <div style={{
			left: `${(Math.random() * 100)}%`,
			top: `${(Math.random() * 100)}%`,
			position: "absolute",
			margin: 0,
			height: "1px",
			width: "1px",
			display: "flex", alignItems: "center", justifyContent: "center",
			zIndex: this.props.zIndex
		}}>
			<div id="letter" className="letter" style={{
				textAlign: "center",
				textJustify: "top",
				fontSize: "5em",
				color: this.randomHex()
			}}>{this.props.letter}</div>
		</div>
	}
}

export default LettersOnScreen