import React from "react"
import aloparpImage from "./aloparp.webp"
import "./Style.css"
global.goingToStop = false

class Aloparp extends React.Component {
    parp() {
        document.getElementById("aloparp").classList.add("AloparpSpin")            
        if (!global.goingToStop) {
            document.getElementById("parp").play()

            setTimeout(() => {
                document.getElementById("aloparp").classList.remove("AloparpSpin")
                global.goingToStop = false
            }, 500)
        }

        global.goingToStop = true
    }

    render() {
        return <div className="AloparpDiv">
            <audio id="parp" src="fart_soudn.mp3" preload="auto"></audio> 
            <img id="aloparp" noselect draggable="false" src={aloparpImage} alt="aloparp" className="AloparpHimself" onMouseDown={this.parp}></img>
        </div>
    }
}

export default Aloparp