import * as React from "react"
import "./Style.css"
const { useEffect, useRef } = React

export default function Nyanvas() {
	let settingsActive = false
	let messageBoxRef = useRef()

	useEffect(() => {
		document.addEventListener("keydown", handleKeypress, false)

		return () => {
			document.removeEventListener("keydown", handleKeypress, false)
		}
	}, [])

	function handleKeypress(event) {
		let key = event.key

		if (!messageBoxRef.current) return

		messageBoxRef.current.displayMessage(key)

		console.log(messageBoxRef)
	}

	return <div id="nyanvas" className="nyanvas">
		{settingsActive ? <Settings /> : null}
		<Logo />
		<MessageBox ref={messageBoxRef}/>
		<Canvas />
	</div>
}

function Canvas() {
	return <div></div>
}

function Settings() {
	return <div></div>
}

function MessageBox() {
	// constructor(props) {
	// 	super(props)
	// 	this.state = {
	// 		currentKey: 0,
	// 		messages: [],
	// 	}

	// 	this.displayMessage = this.displayMessage.bind(this)
	// }

	// function displayMessage(messageText) {

	// 	this.setState(prevState => {
	// 		return {
	// 			currentKey: prevState.currentKey + 1,
	// 			messages: [<Message message={messageText} key={this.state.currentKey}/>, ...prevState.messages, ]
	// 		}
	// 	}, () => {
	// 		console.log(this.state.messages)
	// 	})
	// }

	let [currentKey, setKey] = React.useState(0)
	let [messages, setMessages] = React.useState([])

	function displayMessage(messageText) {
		setMessages(previousState => {
				return [<Message message={messageText} key={currentKey}/>, ...previousState]
			}		
		)
	}

	return <div id="message-box" className="message-box">{messages}</div>
}

function Message({ message }) {
	return <h1 className="fading-message">{message}</h1>
}

const Logo = () => {
	return <div className="nyanvas-logo">
		<h1 style={{color: "#7fa2f1"}} className="nyanvas-text nyan-colors">
			NYAN
		</h1>
		<h1 style={{color: "#ffffff"}} className="nyanvas-text">
			VAS
		</h1>
	</div>
}